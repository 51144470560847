import React, { useState } from "react"
import PropTypes from "prop-types"
import {theme, CSSReset, ThemeProvider } from "@chakra-ui/core";
import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import Header from "./summitHeader";
//import martin from "../assets/img/bg-img-op.jpg"
import Menu from "../components/s-menu ";
import LiveChat from 'react-livechat';


const GlobalStyle = createGlobalStyle`
${normalize}
:root {
  --primary: #ABCB4C;
  --secondary: #006BB4; 
  --logoHeading:2.618em;
  --fontPara:"Open Sans"
}


* {
  text-decoration: none;
  
}
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    
  
  
}
body {
  font-size: 16px;
  font-family: 'Montserrat', 'Uni Neue' -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #F8F7F5;  
   background: linear-gradient(to bottom, #141e30, #243b55); 
   /* background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);  */
  /* background: url('https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive/v1599754193/next-legal/Bytelex-bg_w0vfbt.jpg') no-repeat center top;  */
  overscroll-behavior: none;
  overflow-x: hidden;
  color: white;
  min-height: 100vh;
  z-index:99;
  position:relative;
}

h1{
  font-size: 2.618em;
}

h2{
  font-size: 1.618em;
}

p{
  font-size: 1.1250em; 
}
`



const SLayout = ({ children }) => {

//menu function
const [toggleMenu, setToggleMenu] = useState(false);  
 

  return (
<ThemeProvider theme={theme}>
  <GlobalStyle/>
  <CSSReset/>
  <Menu
  toggleMenu={toggleMenu}
  setToggleMenu={setToggleMenu}
  />
  <Header
     toggleMenu={toggleMenu}
     setToggleMenu={setToggleMenu}
  />

  <main>{children}</main>
  
</ThemeProvider>
  
  )
}

SLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SLayout;
