import React from 'react'
import Layout from '../../components/s-Layout';
import { navigate } from "gatsby";
import "../../styles/tailwind.css";
import "../../styles/trackStyles.scss";
import "../../styles/doneStyles.scss";

import { motion } from 'framer-motion';
import Sponsers from '../../components/sponsers';



const track = () => {
    return (
    <Layout>
        <motion.div
          className="t-welcome"
          animate={{y:0 , opacity: 1}}
          initial={{ y: 72, opacity: 0 }}
          transition={{
            duration: 0.5,
            ease: [0.6, 0.05, -0.01, 0.9],
            /* staggerChildren: 0.1 */
          }}
        >
        <div className="welcome">
            <h1 className="text-3xl flex welcome-title">Choose Track</h1>
            <div className="navigation">
                <button className="px-6 py-3 mt-2  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/app/summit/founder')} }>I am a Founder</button>
                <button className="px-6 py-3 mt-2  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/app/summit/investor')} }>I am an Investor</button>
                <button className="px-6 py-3 mt-2  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/app/summit/lawyer')} }>I am a Lawyer</button>
            </div>
        </div>
        <Sponsers/>
        </motion.div>
    </Layout>
    )
}

export default track
