import React from 'react';
import Layout from '../s-Layout';
import "../../styles/s-sessions.scss";
import "../../styles/tailwind.css";
import { navigate } from "gatsby";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from 'gatsby';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import Filter from '../../assets/img/filter.svg';
import { Select } from "@chakra-ui/core";
import useDate from "react-use-date";



const Agenda = () => {

    const data = useStaticQuery(graphql`
    query thisOne4 {
        allAirtable(sort: {fields: data___startTime}) {
          nodes {
            data {
              speaker
              countryName
              startTime
              finishTime
              track
              topic
              sessLink
              sessvID
              netRoom
            }
          }
        }
      }
    `)   

const {allAirtable:{nodes}} = data;

const initialState = {
  talks: nodes,
  filter:'',
  speakerfilter:''
}


const [state, setState] = useState(initialState);


//console.log(state);

const {talks, filter, speakerfilter } = state;
let showTalks = talks;

//console.log(showTalks);

if (filter) {
 /*  console.log(filter); */
  /* console.log(talks); */
  showTalks = talks.filter((node) => {
   return node.data.countryName.includes(filter) 
  });

}

if (speakerfilter) {
  /*  console.log(filter); */
   /* console.log(talks); */
   showTalks = talks.filter((node) => {
    return node.data.speaker.includes(speakerfilter) 
   });
 
 }

const handleChange = (e) => {
  /* console.log(e.target.value) */
  setState({
    talks:nodes, 
    filter: e.target.value,
    speakerfilter:''
  });

}

const handleSpeakerChange = (e) => {
  /* console.log(e.target.value) */
  setState({
    talks:nodes, 
    filter: "",
    speakerfilter: e.target.value
  
  });


}

//convert date to sting
const dateConvert = (string) => {
  const time = new Date(string);
  return time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
}

//gettime in milliseconds
const miliConvert = (string) => {
  const milli = new Date(string);
  return milli.getTime();
}

//compare time
const compareTime = (curr,start,end) => {
    return curr>start && end>curr
}

//get date
const date = useDate({ interval: 'minute' });

    return (
        <Layout>
            <motion.div 
               animate={{y:0 , opacity: 1}}
               initial={{ y: 72, opacity: 0 }}
               transition={{
                 duration: 0.5,
                 ease: [0.6, 0.05, -0.01, 0.9],
                 /* staggerChildren: 0.1 */
               }}
            className="ss-container ">
            <div className="s-sessions-title">Sessions</div>
            <p className="s-sessions-times">10th December, 2021. All times in EAT</p>
            {/* <div className="filterBox flex ">
                    <div className="filter flex mr-4 items-center">
                      <span><img src={Filter} alt="filter icon" className="w-4 mr-4"/></span><h6 className="text-sm uppercase tracking-wide">filter by</h6>
                    </div>
                    <div className="selector">
                        <Select className="serector" variant="outline" placeholder="Country" value={filter} onChange={handleChange}>
                            <option value="">All</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Uganda">Uganda</option>
                            <option value="USA">USA</option>
                        </Select>
                    </div>

                    <div className="selector-speaker">
                        <Select className="serector" variant="outline" placeholder="Speaker" value={speakerfilter} onChange={handleSpeakerChange}>
                            <option value="" className="others">All</option>
                            <option value="Abu bakr Cassim">Abu bakr Cassim</option>
                            <option value="Abraham Muthia">Abraham Muthia</option>
                            <option value="Damilola Thompson">Damilola Thompson</option>
                            <option value="Dorna Moini">Dorna Moini Samuel Kitara</option>
                            <option value="Larry Yon">Larry Yon</option>
                            <option value="Jared Coreira">Jared Coreira</option>
                            <option value="Raymond Asiimwe">Raymond Asiimwe</option>
                            <option value="Samuel Kitara">Samuel Kitara</option>
                        </Select>
                    </div>
                </div> */}
            <div className="s-sessions">
                {showTalks.map((node) => (
                        <div key={nanoid()} className="s-session">
                          {/* {console.log(compareTime(date.getTime(), miliConvert(node.data.startTime), miliConvert(node.data.finishTime)))} */}
                         {/*  {console.log(date.getTime() > miliConvert(node.data.startTime)  &&  date.getTime() < miliConvert(node.data.finishTime))} */}
                            <span className="time">{`${dateConvert(node.data.startTime)} - ${dateConvert(node.data.finishTime)}`}</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">{node.data.topic}</h2>
                                <h2 className="sess-title mt-2">{node.data.speaker}</h2>
                                <div className="session-actions">
                                    <button className="px-6 py-3 mt-4 uppercase tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { 
                                      navigate('/app/summit/session',
                                      {
                                        state: { ytLink: node.data.sessLink ,
                                                ytVid:node.data.sessvID,
                                                chatRoom:node.data.netRoom,
                                                endTime:node.data.finishTime
                                        }
                                      }
                                      )

                                  
                                  } }>watch stream</button>
                                    {   
                                      compareTime(date.getTime(), miliConvert(node.data.startTime), miliConvert(node.data.finishTime))
                                        &&
                                      <svg className="live" id="Layer_1" height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m6.5 44.096h115v39.808h-115z" fill="#fb4a59"/><g fill="#f2f2f2"><path d="m58.131 71.343v3.648h-15.263v-21.982h3.968v18.334z"/><path d="m61.428 53.009h3.972v21.982h-3.972z"/><path d="m91.186 53.009-9.186 21.982h-4.445l-9.184-21.982h4.416l6.976 16.671 6.976-16.671z"/><path d="m98.1 56.657v5.312h11.359v3.647h-11.359v5.728h12.543v3.648h-16.543v-21.983h16.543v3.648z"/><circle cx="24.192" cy="64" r="6"/></g></svg>
                      
                                      }
                                </div>
                            </div>
                        </div>

                )
                )}
           </div>
           </motion.div>
        </Layout>
        
    )
}

export default Agenda
