import React from 'react'

import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute";
import Summit from "../components/summit/index"
import Agenda from "../components/summit/agenda"
import Sponsors from "../components/summit/sponsors"
import Lobby from "../components/summit/lobby"
import Session from "../components/summit/session"
import Founder from "../components/summit/founder"
import Investor from "../components/summit/investor"
import Lawyer from "../components/summit/lawyer"
import Networking from "../components/summit/networking"
import Speakers from "../components/summit/speakers"
import Kray from "../components/summit/kayfinance"
import Track from "../components/summit/track"

const app = () => {
    return (
        <div>
            <Router basepath="/app">
               {/*  <Summit  path="/summit"></Summit>
                <Lobby path="/summit/lobby"></Lobby>
                <Sponsors path="/summit/sponsors"></Sponsors>
                <Session path="/summit/session"></Session>
                <Agenda path="/summit/agenda"></Agenda> */}
               <PrivateRoute path="/summit" component={Summit} ></PrivateRoute> 
               {/*  <PrivateRoute path="/summit/speakers" component={Summit} > </PrivateRoute>  */}
                <PrivateRoute path="/summit/lobby" component={Lobby} > </PrivateRoute> 
                <PrivateRoute path="/summit/sponsors" component={Sponsors} > </PrivateRoute>
                <PrivateRoute path="/summit/session" component={Session} > </PrivateRoute>
                <PrivateRoute path="/summit/agenda" component={Agenda} > </PrivateRoute>
                <PrivateRoute path="/summit/founder" component={Founder} > </PrivateRoute>
                <PrivateRoute path="/summit/investor" component={Investor} > </PrivateRoute>
                <PrivateRoute path="/summit/lawyer" component={Lawyer} > </PrivateRoute>
                <PrivateRoute path="/summit/networking" component={Networking} > </PrivateRoute>
                <PrivateRoute path="/summit/speakers" component={Speakers} > </PrivateRoute>
                <PrivateRoute path="/summit/krayfinance" component={Kray} > </PrivateRoute>
                <PrivateRoute path="/summit/track" component={Track} > </PrivateRoute>
      {/* <PrivateRoute path="/summit"></PrivateRoute> */}
            </Router>
        </div>
        
    )
}

export default app
