import React from 'react'
import Layout from '../../components/s-Layout';
import "../../styles/sponsorsStyles.scss";
import { motion } from 'framer-motion';
import { navigate } from "gatsby";
import { Helmet } from 'react-helmet';
import sp5 from '../../assets/img/blex.png'
import sp6 from '../../assets/img/documate.png'
import sp7 from '../../assets/img/NBA.png'

const sponsors = () => {
    return (
        <Layout>
        <Helmet>
        <title>Next.Legal</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://nextlegal.io" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal Lobby" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
        </Helmet>
            <motion.div
                  animate={{y:0 , opacity: 1}}
                  initial={{ y: 72, opacity: 0 }}
                  transition={{
                    duration: 0.5,
                    ease: [0.6, 0.05, -0.01, 0.9],
                    /* staggerChildren: 0.1 */
                  }}
            >
            <div className="sponsor-title">Sponsors</div>
            <div className="sponsor-title sub-title">Welcome, click on a sponsor below to enter their booth.</div>
            <div className="sponsors">
                <motion.div
                whileHover={{ scale: 1.1}} 
                className="sponsor rounded overflow-hidden shadow-lg">
                     <img className="sponsor-img" src={sp5}/>
                </motion.div>
                <motion.div
                whileHover={{ scale: 1.1}}  
                className="sponsor rounded overflow-hidden shadow-lg mx-6">
                     <img className="sponsor-img" src={sp7}/>
                </motion.div>
            </div>
            </motion.div>
        </Layout>
    )
}

export default sponsors
