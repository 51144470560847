import React from 'react'
import Layout from '../../components/s-Layout';
import { navigate } from "gatsby";
import "../../styles/tailwind.css";
import "../../styles/summitStyles.scss";
import { motion } from "framer-motion";
import Sponsers from '../../components/sponsers';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import LiveChat from 'react-livechat';
//import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'



const Summit = () => {
    const data = useStaticQuery(graphql`
   query {
      file(name: { eq: "back" }) {
        childCloudinaryAsset {
          fluid (maxWidth: 1960){
            ...CloudinaryAssetFluid
            ...CloudinaryAssetFluidLimitPresentationSize
          }
        }
      }
    }
  `);

    return (
    <Layout>
               <Helmet>
        <title>Next.Legal</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://nextlegal.io" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal Lobby" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
        </Helmet>
    
        <BackgroundImage
            Tag="section"
            className="back"
            fluid={data.file.childCloudinaryAsset.fluid}
            backgroundColor={`#040e18`}
            preserveStackingContext={true}
        >
        <motion.div 
        className="welcome"
        animate={{y:0 , opacity: 1}}
        initial={{ y: 72, opacity: 0 }}
        transition={{
          duration: 0.5,
          ease: [0.6, 0.05, -0.01, 0.9],
          /* staggerChildren: 0.1 */
        }}
        
        >
            <h1 className="text-xl flex welcome-title"><div>Welcome to Next<strong className="dot">.</strong>Legal Summit Online</div></h1>
            <div className="navigation">
            <button className="px-6 py-3 mt-5 tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { 
              navigate('/app/summit/session',
              {
                state: { ytLink:'https://www.youtube.com/embed/QZJdk432MqY',
                        ytVid:'QZJdk432MqY',
                        chatRoom:'https://meet.jit.si/next.Legal-KeynoteRoom',
                        endTime:"2020-10-23T11:15:00.000Z"
                }
              }
            )} }>Keynote</button>
                <button className="px-6 py-3 mt-2  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/app/summit/agenda')} }>Agenda at a Glance</button>
                <button className="px-6 py-3 mt-2  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/app/summit/track')} }>Choose Track</button>
                <button className="px-6 py-3 mt-2  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/app/summit/sponsors')} }>Sponsor Hall</button>
               {/*  <button className="px-6 py-3 mt-2  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/app/summit/networking')} }>Startups & Investors</button> */}
            </div>
            {typeof window !== 'undefined' && <LiveChat license={12289827} />  }
            <Sponsers/>
        </motion.div>
        </BackgroundImage>  
    </Layout>
    )
}

export default Summit
