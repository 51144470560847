import React from 'react';
import Layout from '../../components/s-Layout';
import "../../styles/booth.scss";
import "../../styles/tailwind.css";
//import { navigate } from "gatsby";
import { motion } from 'framer-motion';
import { List, ListItem, ListIcon } from "@chakra-ui/core";
//import lex from '../../assets/img/lexcharge_advantage-02.jpg';
import LiveChat from 'react-livechat';

const LexCharge = () => {
    return (
        <Layout>
            <motion.div
               animate={{y:0 , opacity: 1}}
               initial={{ y: 72, opacity: 0 }}
               transition={{
                 duration: 0.5,
                 ease: [0.6, 0.05, -0.01, 0.9],
                 /* staggerChildren: 0.1 */
               }}
            >
            <div className="booth-content">
            <h2 className="booth-title">Kray Micro finance</h2>
            <p className="booth-para">Kray Micro finance is a Tier 4 Micro finance company, licensed and regulated by Uganda Micro finance Regulatory Authority. We have been in existence since 2012 serving small and medium enterprises (SMEs) with affordable credit.</p>
            <div className="booth-box">
              {/*   <div className="booth-video">
                    <img src={lex} alt=""/>
                    <div className="booth-video-box">
                        <div className="booth-event-video">
                                  <iframe className="booth-event-video-iframe" loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/R987yqN52M8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div> */}
                 <div className="boothvid">
                    <div class="embed-responsive aspect-ratio-16/9">
                    <iframe src="https://drive.google.com/file/d/11u4AWhCcmMOTgrcPqs0V9wo3S28kpqyj/preview"></iframe>
                    </div>
                </div>
                <div className="list">
                <List spacing={3}>
                    <ListItem>
                            <ListIcon icon="check-circle" color="green.500" />
                            Fast.Your loan is processed within 24 hours straight to your mobile phone
                        </ListItem>
                        <ListItem>
                            <ListIcon icon="check-circle" color="green.500" />
                            Convenient.You access our service anytime, anywhere in the comfort of your palms
                        </ListItem>
                        <ListItem>
                            <ListIcon icon="check-circle" color="green.500" />
                            Reliable. We are there for you wherever, whenever you need us, 24/7.
                        </ListItem>
                    </List>
                </div>
          
            </div>
                <div className="booth-btn">
                    <a href="https://kraymicrofinance.com/contact-us/" target="_blank" rel="noopener norefferer">
                    <button className="px-6 py-3 mt-4 uppercase tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" /* onClick={() => { window.LC_API.open_chat_window();} } */>Get in touch</button> 
                    </a>   
                </div>
           </div>
           </motion.div>
           {typeof window !== 'undefined' && <LiveChat license={12220860} />  }
        </Layout>
        
    )
}

export default LexCharge
