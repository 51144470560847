import React from "react"
import PropTypes from "prop-types"
/* import {
    useNetlifyIdentity,
  } from "react-netlify-identity-widget"
import { navigate } from "gatsby" */

/* const PrivateRoute = ({component: Component, location, ...rest})  => {
    const identity = useNetlifyIdentity()
    const isLoggedIn = identity && identity.isLoggedIn
    const { component: Component, location, ...rest } = props
    
    React.useEffect(
      () => {
        if (!isLoggedIn && location.pathname !== `/login`) {
          // If the user is not logged in, redirect to the login page.
          navigate(`/login`)
        }
      },
      [isLoggedIn, location]
    )

    return isLoggedIn ? <Component {...rest} /> : null

  }

  PrivateRoute.prototype ={
      component:PropTypes.any.isRequired,
  } */


  const PrivateRoute = ({ component: Component, location, ...rest }) => {

   /*  const identity = useNetlifyIdentity()
    const isLoggedIn = identity && identity.isLoggedIn
    

    if (!isLoggedIn && location.pathname !== `/logy`) {
        // If the user is not logged in, redirect to the login page.
        navigate(`/logy`)
        return null
      } */

      return <Component {...rest} />
    }
  
  
  PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
  }

  export default PrivateRoute

