import React from 'react';
import Layout from '../../components/s-Layout';
import "../../styles/speakerStyles.scss";
import { motion } from 'framer-motion';
import { Speakers } from '../../components/summitSpeakers';

const speakers = () => {
    return (
        <Layout>
            <motion.div
                 animate={{y:0 , opacity: 1}}
                 initial={{ y: 72, opacity: 0 }}
                 transition={{
                   duration: 0.5,
                   ease: [0.6, 0.05, -0.01, 0.9],
                   /* staggerChildren: 0.1 */
                 }}
            >
                 <Speakers/>
            </motion.div>
        </Layout>
    )
}

export default speakers
