import React from 'react';
import Layout from '../../components/s-Layout'
import "../../styles/sessionStyles.scss";
import YouTube from 'react-youtube';
import toaster from 'toasted-notes' 
import 'toasted-notes/src/styles.css';
import "../../styles/tailwind.css";
import { motion } from 'framer-motion';
import { navigate } from "gatsby";
import Sponsers from '../../components/sponsers';
import Countdown from 'react-countdown';
import { Location } from '@reach/router';




const session = () => {
  /* console.log(location.state.ytVid)
  console.log(location.state.ytLink); */
    const opts = {
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };


const notice = () => {
    toaster.notify(
        "Session Complete. Enter networking room or return to Lobby.",
        {
            duration: 5000,
            position: 'top-right'
          }
    );
}


    return (
        <Layout>
          
            <motion.div
               animate={{y:0 , opacity: 1}}
               initial={{ y: 72, opacity: 0 }}
               transition={{
                 duration: 0.5,
                 ease: [0.6, 0.05, -0.01, 0.9],
                 /* staggerChildren: 0.1 */
               }}
            >
            
            <div className="session">
                <div className="sess-container">
 
                  

                 <div className="sessvid">
                    <div class="embed-responsive aspect-ratio-16/9">
                      { typeof window !== `undefined` && 
                      <Location> 
                      {({ location }) => {
                          return <iframe src={location.state.ytLink} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>;
                        }}
                      </Location> 
                        }
                    </div>
                </div> 

             <div className="sesschatbox">
                
                 {typeof window !== `undefined` && 
                 <Location> 
                 {({ location }) => {
                     return <iframe height="100%" src={`https://www.youtube.com/live_chat?v=${location.state.ytVid}&embed_domain=${location.hostname}`} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                   }}
                 </Location> 
                 
                 }
                </div>

               {/*  <div className="video-box">
                    <YouTube className="video1" videoId="oj9X4dPUf_8" opts={opts}/>      
                </div> */}
                {/* <div className="chatbox">
                  <iframe className="chat" height="100%" src="https://www.youtube.com/live_chat?v=oj9X4dPUf_8&embed_domain=localhost" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>  */}
                </div>
                <div className="bottom">
                  <Sponsers className="sponserbanner"/>
                 {/*  <div className="s-net">
                    {typeof window !== `undefined` && 
                    <Location> 
                    {({ location }) => {
                        return     <a href={`${location.state.chatRoom}`} target="_blank" rel="noopener norefferer">
                                      <button className="s-netbot px-6 py-3 mt-4 uppercase tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full">Networking Room</button>
                                  </a>
                      }}
                    </Location> 
                    
                    }
                     
                  </div> */}
                </div> 
            </div>
            {/* {location.state.endTime} */}
            {typeof window !== `undefined` && 
            <Location> 
            {({ location }) => {
                return  <Countdown date={new Date(location.state.endTime)} onComplete={()=>notice()}/> 
              }}
            </Location> 
           
            } 
            </motion.div>
        </Layout>
        
    )
}

export default session
