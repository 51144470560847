import React, {useState, useEffect, useCallback} from 'react';
import Layout from '../../components/layout';
import Footer2 from '../../components/footer2';
import { Link } from "gatsby"

import "../../styles/signinStyles.scss";
import sp5 from '../../assets/img/blex.png'
import sp6 from '../../assets/img/documate.png'


import "../../styles/tailwind.css";
import { Helmet } from 'react-helmet';
/* import IntervalTimer from 'react-interval-timer'; */
import { navigate } from "gatsby";
import { motion} from 'framer-motion';
import { Checkbox } from 'pretty-checkbox-react';

const Summit = props => {

  const [checked, setChecked] = useState(false);
 
  const handleChange = useCallback(() => {
      setChecked(prev => !prev);
  }, []);

  useEffect(() => {
    if (checked) {
        // perform some side effect
        // when the state changes
        // navigate('/summit/lobby')
    
    }
}, [checked]);

    return <Layout>
        <Helmet>
        <title>Next.Legal</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://nextlegal.io" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal Lobby" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
        </Helmet>
        {/* <IntervalTimer
                    timeout={4000}
                    callback={()=>{
                      navigate('/summit/lobby');
                     }}
                    enabled={true}
                /> */}
        <div className="signin-container">
          <motion.div 
               animate={{y:0 , opacity: 1}}
               initial={{ y: 72, opacity: 0 }}
               transition={{
                 duration: 0.5,
                 ease: [0.6, 0.05, -0.01, 0.9],
                 /* staggerChildren: 0.1 */
               }}
          className="signin-content"
          >
            <h2 className="signin-title">Let us get you into the lobby.</h2>

          <div className="flex">
              <Checkbox className=" mt-1" state={checked} onChange={handleChange}/>
             <span>
                  <p className="signin-warning mt-0">I agree to the Next.Legal Event Terms and Conditions and the Next.Legal Community Codes of Conduct.</p>
              </span>
          </div>  
            <div>
              <Link
              to="/app/summit/lobby"
              >
                 <button className=" mt-3 px-6 py-2 text-white font-light tracking-wide hover:bg-blue-500 rounded-full signin-proceed-btn" /* onClick={() => { navigate('app/summit/lobby')} } */>PROCEED</button>
              </Link>
            </div>
            <div className="signin-sponsors-container">
              <h2 className="signin-sponsor-text tracking-wide uppercase">sponsored by</h2>
              <div className="signin-sponsors">
                    <div className="signin-sponsor">
                      <img src={sp5} alt="" className="signin-sponsor-img"/>
                    </div>
              </div>
            </div>
            </motion.div>
            <Footer2/>
          </div>
       
    </Layout>
}

export default Summit
