import React from 'react'
import Cancel from '../assets/img/canel-icon.svg'
import '../styles/s-menuStyles.scss'
import { motion, AnimatePresence } from "framer-motion"
import { navigate } from "gatsby";

const smenu = ({ toggleMenu, setToggleMenu }) => {

    return (<>
    <AnimatePresence>
    { toggleMenu && (
        <motion.div className="s-menu-container"
            initial={{ x: "-100%" }}
            exit={{ x: "-100%" }}
            animate={{ x: toggleMenu ? 0 : "-100%" }}
            transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
            >
                <nav>
                    <div className="logo">
                    </div>
                    <ul className="nav">
                        <li className="menu"><button onClick={ () => setToggleMenu(!toggleMenu) } className="cancel"><img src={Cancel} alt="close button"/></button></li>
                    </ul>
                </nav>
                <div className="line"></div>
                <ul className="nav-links">
                    <li className="link"onClick={() => { navigate('/summit') }  }>Lobby</li>
                    <li className="link"onClick={() => { navigate('/agenda')} }>Sessions</li>
                    <li className="link"onClick={() => { navigate('/sponsors')} }>Expo Hall</li>
                </ul>
                <div className="social-links">
                    <span className="sl">FB.</span>
                    <span className="sl">TW.</span>
                    <span className="sl">LI.</span>
                </div>
                <p className="copyright">©{new Date().getFullYear()} Bytelex Advocates</p>
            </motion.div>
    )
        } 
        </AnimatePresence>
        </>
    )
}

export default smenu
