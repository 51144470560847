import React from 'react'
import '../styles/summitHeaderStyles.scss';
import Menu from "../assets/img/smenu-icon.svg";
import logo from '../assets/img/next-legal.svg';
import { navigate } from "gatsby";


const SummitHeader = ({setToggleMenu, toggleMenu}) => {
    

    return (
        <>
        <div className="sh-container">
            <div className="hlbox">
                <img className="h-logo" src={logo} alt="" onClick={() => { navigate('/app/summit/lobby')} } />
            </div>
            <ul className="h-nav">
                <li className="h-link lobby" onClick={() => { navigate('/app/summit/lobby')} }>Lobby</li>
                <li className="h-link" onClick={() => { navigate('/app/summit/agenda')} }>Sessions</li>
                <li className="h-link" onClick={() => { navigate('/app/summit/sponsors')} }>Expo Hall</li>
                <li className="sh-Menu"><button onClick={() => setToggleMenu(!toggleMenu)}><img src={Menu} alt="menu button"/></button></li>
            </ul>
        </div>
        </>
    )
}

export default SummitHeader;
